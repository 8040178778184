/**
 * Mocking client-server processing
 */
const _users = [
    { id: '117086d1-4235-4445-9909-e77ef9ff0f33', telegram: 'COLD54', tg_userid: '431345785', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExNzA4NmQxLTQyMzUtNDQ0NS05OTA5LWU3N2VmOWZmMGYzMyIsInVzZXJuYW1lIjoiQ09MRDU0IiwiaWF0IjoxNjgzMjA3MjA1fQ.Ycn_brszcecPhYkeSXgviM6or5O-JWAhCFdY8LH53L8', enable: '0', roles: [ 'user' ], teams: ['vspo' ], createdAt: '2023-05-04 13:33:25', updatedAt: '2023-05-04 13:37:27' },
    { id: '316a0f4e-e0bd-493c-a47b-8d9ba49ebb3e', telegram: 'Misha_chel', tg_userid: '561911132', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMxNmEwZjRlLWUwYmQtNDkzYy1hNDdiLThkOWJhNDllYmIzZSIsInVzZXJuYW1lIjoiTWlzaGFfY2hlbCIsImlhdCI6MTY4MzIwNjI4Nn0.THG-OYmvqmIJoFozbZyoygUa9EeiBe8y0kL6BJWyGPk', enable: '1', roles: ['user' , 'moderator' ], teams: [ 'vspo' , 'any' ], createdAt: '2023-05-04 13:18:06', updatedAt: '2023-05-04 13:22:38' },
    { id: '4fe06998-4ae0-4da1-a43c-58c6cef11e1b', telegram: 'Portreter2', tg_userid: null, token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjRmZTA2OTk4LTRhZTAtNGRhMS1hNDNjLTU4YzZjZWYxMWUxYiIsInVzZXJuYW1lIjoiUG9ydHJldGVyMiIsImlhdCI6MTY4MzIwNzcwN30.UOFmUmz2OtG98PqKkxHuF1Elu38mfo76_721aeou-18', enable: '1', roles: [ 'user' ], teams:  [ 'vspo' , 'pidor' ], createdAt: '2023-05-04 13:41:47', updatedAt: '2023-05-04 13:55:22' },
    { id: '5b7507ba-7610-4658-86be-12116792b056', telegram: 'Keshaliv', tg_userid: '1765856480', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNzUwN2JhLTc2MTAtNDY1OC04NmJlLTEyMTE2NzkyYjA1NiIsInVzZXJuYW1lIjoiS2VzaGFsaXYiLCJpYXQiOjE2ODMyMDczNTN9.9XbNKk1C2p5HxSV2UaiTywTxSY_7iDbNEwoHarQdew4', enable: '1', roles: [ 'user' ], teams: ['vspo' ], createdAt: '2023-05-04 13:35:53', updatedAt: '2023-05-06 13:49:46' },
    { id: '7330c7b4-20c2-43de-a8d7-d125075e6932', telegram: 'neonclovds', tg_userid: '341480800', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjczMzBjN2I0LTIwYzItNDNkZS1hOGQ3LWQxMjUwNzVlNjkzMiIsInVzZXJuYW1lIjoibmVvbmNsb3ZkcyIsImlhdCI6MTY4MzIwNzA1Nn0.dvItndshgeZffLRlDyXk8Kt6SQGvXaxEoOVCiITNPug', enable: '1', roles: [ 'user' ], teams: [ 'vspo' , 'sobaka' ], createdAt: '2023-05-04 13:30:56', updatedAt: '2023-05-06 17:30:40' },
    { id: '7da4e42f-7fea-419e-ac69-c90ad386db89', telegram: 'murd187', tg_userid: '5756100556', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjdkYTRlNDJmLTdmZWEtNDE5ZS1hYzY5LWM5MGFkMzg2ZGI4OSIsInVzZXJuYW1lIjoibXVyZDE4NyIsImlhdCI6MTY4MzIwNzA0Mn0.xOJgr138jz4oG_NaMIrmyOFmz9pV9-vw3HRdi_09-Rs', enable: '1', roles: [ 'user' ], teams: ['vspo' ], createdAt: '2023-05-04 13:30:42', updatedAt: '2023-05-04 13:39:17' },
    { id: '98632caf-36c3-4354-8158-d44b63426b23', telegram: 'samik_png', tg_userid: '5560105204', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk4NjMyY2FmLTM2YzMtNDM1NC04MTU4LWQ0NGI2MzQyNmIyMyIsInVzZXJuYW1lIjoic2FtaWtfcG5nIiwiaWF0IjoxNjgzMjA4MjYyfQ.cGX_djixQlchimPg3NsdFeejOchABvDY8gGk0MxUNUs', enable: '1', roles: [ 'user' ], teams: ['vspo' ], createdAt: '2023-05-04 13:51:02', updatedAt: '2023-05-04 15:14:58' },
    { id: '9daf60cf-2183-4d3c-8124-79a17094bd75', telegram: 'hfxxxdd', tg_userid: '1032422748', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjlkYWY2MGNmLTIxODMtNGQzYy04MTI0LTc5YTE3MDk0YmQ3NSIsInVzZXJuYW1lIjoiaGZ4eHhkZCIsImlhdCI6MTY4MzMwMjQwNn0.xlbEidWn9kw8tOuRKs7Wx3tqS440MakccAQfmyz4cN8', enable: '1', roles: [ 'user' ], teams: [ 'vspo' , 'any', 'chort', 'sobaka' ], createdAt: '2023-05-05 16:00:06', updatedAt: '2023-05-05 16:03:00' },
    { id: 'a14c20d1-09ec-4de9-bb3f-208f320b7192', telegram: 'zememaz', tg_userid: '5779337060', token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImExNGMyMGQxLTA5ZWMtNGRlOS1iYjNmLTIwOGYzMjBiNzE5MiIsInVzZXJuYW1lIjoiemVtZW1heiIsImlhdCI6MTY4MzIxMTMzNX0.3UQJHe-TmzqL7M7Z4tjRsEy8dNww-jnEHM2J69kFT3g', enable: '1', roles: [ 'user' ], teams: ['vspo' ], createdAt: '2023-05-04 14:42:15', updatedAt: '2023-05-04 14:45:12' },

]

const _AllRoles = ['user','moderator','admin']

const _AllTeams = ['vspo','any']

const _logs = [
    { id: 'fed9ca0c-860a-4b0d-9c6a-8dc18443304e', user: 'Keshaliv', action: 'get twofactor success', message: 'twofactor meta527, odowskiarius@outlook.com - CoinList found', createdAt: '2023-05-06 18:41:17', updatedAt: '2023-05-06 18:41:17' },
    { id: '5b34a396-a254-4348-9f02-c0f75056c45b', user: 'murd187', action: 'get twofactor failed', message: 'twofactor meta 1479 not found', createdAt: '2023-05-06 15:01:41', updatedAt: '2023-05-06 15:01:41' },
    { id: '80d7af87-9162-4881-a07c-0d0b62a7e51f', user: 'Keshaliv', action: 'get twofactor success', message: 'twofactor meta520, anfieldhilip@outlook.com - CoinList found', createdAt: '2023-05-06 18:31:15', updatedAt: '2023-05-06 18:31:15' },
    { id: '873b6608-a40a-4c40-9d69-33e6ab7838f6', user: 'Lossborn', action: 'auth failed', message: 'user disabled', createdAt: '2023-05-05 23:49:21', updatedAt: '2023-05-05 23:49:21' },
]
export default {
    getUsers(cb) {
        setTimeout(() => cb(_users), 100)
    },
    getRoles(cb) {
        setTimeout(() => cb(_AllRoles), 100)
    },
    getTeams(cb) {
        setTimeout(() => cb(_AllTeams), 100)
    },
    getLogs(cb) {
        setTimeout(() => cb(_logs), 100)
    },

}