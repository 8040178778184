import users from './dummy'

// initial state
const state = {
    data: [],
}

// getters
const getters = {}

// actions
const actions = {
    getAllRoles({ commit }) {
        users.getRoles(roleList => {
            commit('setRoles', roleList)
        })
    },
}

// mutations
const mutations = {
    setRoles(state, roleList) {
        state.data = roleList
        
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}