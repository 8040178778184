import { createStore } from 'vuex'
import users from './modules/users'
import teams from './modules/teams'
import roles from './modules/roles'
import logs from './modules/logs'
// Create a new store instance.
const store = createStore({
    modules:{
        users,
        teams,
        roles,
        logs,
    },
  
})



export default store;