<template>
  <v-app id="app">

    <v-app-bar color="primary" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Authenticator</v-app-bar-title>
      <v-btn icon="mdi-refresh" @click="this.$router.go()"></v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> {{ user.telegram }}</v-list-item-title>
          <v-list-item-subtitle> {{ displayRole(user.roles) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>

        <div v-show="userExist && premission">
          <v-list-item v-for="item in items" :key="item.title" :to="item.route">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

      </v-list>
    </v-navigation-drawer>

    <div v-show="userExist">
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </div>

    <v-footer app>

    </v-footer>

  </v-app>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
const tg = window.Telegram.WebApp

export default {
  name: 'App',
  setup() {
    const store = useStore()
    const user = computed(() => store.state.users.current)
    const username = tg.initDataUnsafe?.user?.username

    store.dispatch('users/getCurrentUser', username)
    store.dispatch('logs/getLastLogs', user.value.token)
    return {
      user,
      username,
    }
  },
  data: () => ({
    drawer: false,
    items: [
      { title: 'Users', icon: 'mdi-account-box', route: '/users' },
      { title: 'Logs', icon: 'mdi-view-dashboard', route: '/logs' },
      //{ title: 'Settings', icon: 'mdi-cog', link: '' },
    ],
  }),
  computed: {

    userExist() {
      return this.user?.telegram && this.username && (this.user?.telegram === this.username) ? true : false
    },
    premission() {
      return this.user?.enable && this.user?.roles.includes('moderator') ? true : false
    }
  },

  beforeMount() {

  },
  methods: {
    displayRole(array) {
      if (typeof (array) == 'object') {
        return array.length >= 1 ? array.join(', ') : array.join()
      } else {
        return `unauthorized`
      }
    }
  },

};

</script>

<style></style>
