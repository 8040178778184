import { createWebHistory, createRouter } from "vue-router";
import HomeView from './views/HomePage.vue';
import LogsView from './views/LogsPage.vue';
import UsersView from './views/UsersPage.vue';
//import store from '@/store.js';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/users',
        name: 'users',
        component: UsersView
    },
    {
        path: '/logs',
        name: 'logs',
        component: LogsView,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/* router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (!store.state.isAuthenticated) {
            next({
                path: '/sign-in'
            });
        } else {
            next();
        }
    } else {
        next();
    }
}); */

export default router;