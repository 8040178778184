//import users from './dummy'
import axios from 'axios';
import qs from 'qs';
const API_URL = 'https://adminpanel-tg.ddns.net:11488'

// initial state
const state = {
    data: JSON.parse(localStorage.getItem('data')),
    current: JSON.parse(localStorage.getItem('current'))
}

// getters
const getters = {

}

// actions
const actions = {
    /* getAllUsersDemo({ commit }) {
        users.getUsers(userList => {
            userList.map(user => {
                user.enable == 1 ? user.enable = true : user.enable = false
            })
            commit('setUsers', userList)
        })
    }, */

    getAllUsers({ commit }, token) {
        const authHeader = { 'authorization': token }
        axios.get(API_URL + `/user/all`, { headers: authHeader }).then((response) => {
            let userList = response?.data?.result
            userList.map(user => {
                user.enable == 1 ? user.enable = true : user.enable = false
            })
            commit('setUsers', userList)
        });
    },

    getCurrentUser({ commit }, username) {
        const url = 'https://adminpanel-tg.ddns.net:11488/user/exist'
        const data = { 'payload': username }

        axios({
            method: 'POST',
            headers: { 'authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImRiNDRhYTRhLWE2OGMtNDcyMS04YjUwLTFiMzI5MmMwNDQ0NiIsInVzZXJuYW1lIjoidjAxNWsxeSIsImlhdCI6MTY4MzIwODM0OH0.sJbMgR2UnWUPx4qM_4KyMm6tIMTZyIPPVFTt57sfPPg' },
            data: qs.stringify(data),
            url,
        }).then((response) => {
            commit('setCurrent', response.data.result)
        })

    },

    saveUser({ commit }, payload) {


        const url = 'https://adminpanel-tg.ddns.net:11488/user/savechanges'
        const authHeader = { 'authorization': payload.token }
        delete payload.token
        console.log(payload)
        axios({
            method: 'POST',
            headers: authHeader,
            data: qs.stringify(payload),
            url,
        }).then((response) => {
            console.log(response.data)
            commit('saveUser', response.data.result)
        })

    }

}

// mutations
const mutations = {
    setUsers(state, userList) {
        state.data = userList
        localStorage.setItem('data', JSON.stringify(userList));
    },
    setCurrent(state, user) {
        state.current = user
        localStorage.setItem('current', JSON.stringify(user));
    },
    saveUser(state, message) {
        console.log(state)
        console.log(message)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}