import axios from 'axios';
const API_URL = 'https://adminpanel-tg.ddns.net:11488'

// initial state
const state = {
  data: JSON.parse(localStorage.getItem('data')),
}

// getters
const getters = {

}

// actions
const actions = {

  getLastLogs({ commit }, token) {
    const authHeader = { 'authorization': token }
    axios.get(API_URL + `/logs/latest`, { headers: authHeader }).then((response) => {
      commit('setLogs', response?.data.result)
    });
  },

}

// mutations
const mutations = {
  setLogs(state, logsList) {
    state.data = logsList
    localStorage.setItem('data', JSON.stringify(logsList));
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}