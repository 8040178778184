<template>
    <h1>welcome to the club, buddy</h1>
</template>
  
<script>




export default {
    name: 'HomeView',

};
</script>
  
<style></style>