<template>
    <v-container>
        <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
        <v-row dense>
            <v-col v-for="log in filtredLogs" :key="log.id" cols="12">
                <v-card :title="log.user" :subtitle="log.action" >
                    <v-card-text hide-details>
                        {{ log.message }}
                        <div class="text-overline mb-1">
                            {{ timeFormat(log.createdAt) }}
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'



export default {
    name: 'LogsView',
    setup() {
        const store = useStore()
        const user = computed(() => store.state.users.current)
        const logs = computed(() => store.state.logs.data)


        store.dispatch('users/getCurrentUser', user.value.telegram)
        store.dispatch('logs/getLastLogs', user.value.token)

        return {
            user,
            logs,
        }
    },
    data: () => ({
        selected: [],
        //loading: false,
        search: '',
    }),


    computed: {

        filtredLogs() {
            const search = this.search.toLowerCase()

            if (!search) return this.logs

            return this.logs.filter(item => {
                const user = item.user.toLowerCase()
                return user.indexOf(search) > -1
            })
        },


    },


    watch: {
        selected() {
            this.search = ''
        },
    },

    methods: {
        timeFormat(time) {
            const Time = new Date(time)
            return Time.toLocaleString('ru-RU')
        }
    },

};
</script>
  
<style></style>