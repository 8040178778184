import users from './dummy'

// initial state
const state = {
    data:[],
}

// getters
const getters = {}

// actions
const actions = {
    getAllTeams({ commit }) {
        users.getTeams(teamList => {
            commit('setTeams', teamList)
        })
    }
}

// mutations
const mutations = {
    setTeams(state, teamList) {
        state.data = teamList
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}