<template>
    <v-container>
        <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
        <v-row dense>
            <v-col v-for="usr in filtredUser" :key="usr.id" cols="12">
                <v-card :title="usr.telegram" >
                    <v-divider class="mx-4"></v-divider>
                    <v-list>
                        <v-list-item hide-details>
                            <v-switch v-model="usr.enable" color="purple" label="Enable" @change="onChange"
                                hide-details></v-switch>
                        </v-list-item>
                    </v-list>

                    <v-card-text hide-details>
                        <h3 class=" mb-1">Choose roles</h3>

                        <v-chip-group class="mb-1 mt-1" v-model="usr.roles" mandatory column multiple
                            color="deep-purple-accent-4">
                            <v-chip hide-details v-for="role in allRoles" :key="role" cols="12" filter variant="outlined" :text="role"
                                :value="role">
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>

                    <v-card-text hide-details>
                        <h3 class=" mb-1">Choose teams</h3>

                        <v-chip-group class="mb-1 mt-1" v-model="usr.teams" mandatory column multiple
                            color="deep-purple-accent-4">
                            <v-chip hide-details v-for="team in allTeams" :key="team" cols="12" filter variant="outlined" :text="team"
                                :value="team">
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            @click="save({ telegram: usr.telegram, enable: usr.enable, roles: usr.roles, teams: usr.teams })"
                            variant="text" :loading="loading" block color="deep-purple-accent-4">
                            save
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'


export default {
    name: 'UsersView',
    setup() {

        const store = useStore()
        const user = computed(() => store.state.users.current)
        const users = computed(() => store.state.users.data)
        const allRoles = computed(() => store.state.roles.data)
        const allTeams = computed(() => store.state.teams.data)

        store.dispatch('users/getCurrentUser', user.value.telegram)
        store.dispatch('users/getAllUsers', user.value.token)
        store.dispatch('roles/getAllRoles')
        store.dispatch('teams/getAllTeams')
        return {
            user,
            users,
            allRoles,
            allTeams
        }
    },
    data: () => ({
        selected: [],
        loading: false,
        search: '',
    }),


    computed: {
        filtredUser() {
            const search = this.search.toLowerCase()

            if (!search) return this.users

            return this.users.filter(item => {
                const telegram = item.telegram.toLowerCase()

                return telegram.indexOf(search) > -1
            })
        },

    },


    watch: {
        selected() {
            this.search = ''
        },
    },

    methods: {
        save(payload) {
            this.loading = true
            console.log(payload)
            console.log(this.user.token)

            this.$store.dispatch('users/saveUser', { ...payload, token: this.user.token })
            setTimeout(() => {
                this.search = ''
                this.loading = false
            }, 500)
        },

    },






};
</script>

<style></style>